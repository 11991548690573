













































































import { Mixins, Component } from 'vue-property-decorator';
import Validation from '@/lib/mixins/validation';
import LoginLayout from '@/views/layouts/LoginLayout.vue';
import { store } from '@/store';

@Component({
  name: 'RenewPassword',
  components: { LoginLayout },
})
export default class RenewPassword extends Mixins(Validation) {
  oldPassword = '';
  newPassword = '';

  isShowLoginError = false;

  confirmPasswordRule(value: string) {
    if (value === this.newPassword) return true;
    return '新しいパスワードとパスワード確認が一致しません';
  }

  get searchParams() {
    const url = window.location.href;
    const { searchParams } = new URL(url);
    return searchParams;
  }

  async changePassword(params: { oldPassword: string; newPassword: string }) {
    const email = this.searchParams.get('email');
    const code = this.searchParams.get('code');

    if (email != null && code != null) {
      if (this.$route.name === 'FirstLogin') {
        const { firstLogin } = this.RepoFac.Login;
        const response = await firstLogin({
          email,
          code,
          newPassword: params.newPassword,
        });
        if (response) {
          store.commit('auth')('loggedIn', response);
          this.$router.push({ name: 'AdminView' });
        }
        return;
      }
      const { resetPassword } = this.RepoFac.Login;
      const response = await resetPassword({
        email,
        code,
        newPassword: params.newPassword,
      });
      if (response) {
        store.commit('auth')('loggedIn', response);
        this.$router.push({ name: 'AdminView' });
      }
    } else {
      const { changePassword } = this.RepoFac.Login;
      const response = await changePassword(params);
      if (response) this.$router.go(-1);
      else this.isShowLoginError = true;
    }
  }
}
