


























































import { Vue, Component, Watch } from 'vue-property-decorator';
import TermAndPolicy from '@/lib/typedef/term&condition';
import TermAndPolicyCard from '@/components/organisms/TermAndPolicyCard.vue';
import ConfirmDelete from '@/components/popups/ConfirmDeleteTermAndPolicy.vue';
import { store } from '@/store';

@Component({
  name: 'TermAndPolicyDetail',
  components: {
    TermAndPolicyCard,
    ConfirmDelete,
  },
})
export default class TermAndPolicyDetail extends Vue {
  term = new TermAndPolicy();

  isShowSnackbar = false;
  snackbarContent = '文書は保存されました';

  get type() {
    if (this.$route.name === 'TermDetail') return 'term';
    if (this.$route.name === 'PolicyDetail') return 'policy';
    return 'CommercialCode';
  }

  get getTermAndPolicy() {
    if (this.type === 'term') {
      const { getItemDetail } = this.RepoFac.Term;
      return getItemDetail;
    }

    if (this.type === 'policy') {
      const { getItemDetail } = this.RepoFac.Policy;
      return getItemDetail;
    }

    const { getItemDetail } = this.RepoFac.CommercialCode;
    return getItemDetail;
  }

  @Watch('$route.params.id', { immediate: true })
  async getTermAction(value: string) {
    if (this.$route.params.id !== 'new') {
      const responce = await this.getTermAndPolicy(value);
      if (responce) this.term = responce;
      // If error when fetch detail, return to list screen and show "Error when get detail" popup
      else this.$router.push({ name: this.$route.name?.replace('Detail', '') });
    }
  }

  async deleteItem() {
    store.commit('popup')('setPopup', 'ConfirmDeleteTermAndPolicy');
  }

  updateWhenChange() {
    this.isShowSnackbar = true;
    this.getTermAction(this.$route.params.id);
  }
}
