








































































































import { Mixins, Component } from 'vue-property-decorator';
import Validation from '@/lib/mixins/validation';
import LoginLayout from '@/views/layouts/LoginLayout.vue';
import SendEmail from '@/components/popups/InviteByEmail.vue';
import { store } from '@/store';

@Component({
  name: 'LoginPage',
  components: {
    LoginLayout,
    SendEmail,
  },
})
export default class LoginPage extends Mixins(Validation) {
  email = '';
  password = '';

  isShowSnackbar = false;
  isShowLoginError = false;

  async Login() {
    const { auth } = this.RepoFac.Login;
    const response = await auth(this.email, this.password);
    if (response) {
      store.commit('auth')('loggedIn', response);
      this.$router.push({ name: 'AdminView' });
    } else this.isShowLoginError = true;
  }

  openSendEmailPopup() {
    store.commit('popup')('setPopup', 'InviteByEmail');
  }
}
