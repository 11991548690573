


























































import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'ArrowVerticalIcon',
  components: {},
})
export default class ArrowVerticalIcon extends Vue {}
