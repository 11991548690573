



































import { Vue, Component } from 'vue-property-decorator';
import TheHeader from '@/components/organisms/TheHeader.vue';
import TheSideBar from '@/components/organisms/TheSideBar.vue';

@Component({
  name: 'AdminLayout',
  components: {
    TheHeader,
    TheSideBar,
  },
})
export default class AdminLayout extends Vue {
  get routeName() {
    if (!this.$route.name) return '';
    return this.$route.name;
  }

  get isDetailPage() {
    return this.routeName.indexOf('Detail') > -1;
  }

  returnToList() {
    // if (this.routeName === 'FamilyGroupDetail')
    //   this.$router.push({ name: 'FamilyGroup' });
    // else if (this.routeName === 'UserReportDetail')
    //   this.$router.push({ name: 'UserReport' });
    // else if (
    //   this.routeName === 'TermDetail' ||
    //   this.routeName === 'PolicyDetail' ||
    //   this.routeName === 'CommercialCodeDetail'
    // )
    //   this.$router.push({ name: 'TermAndPolicy' });
    // else if (this.routeName === 'ProfileDetail')
    //   this.$router.push({ name: 'AdminSetting' });
    // else this.$router.go(-1);
    this.$router.go(-1);
  }
}
