import LocalStorageWorker from '@/lib/storage/StorageHelper';
import PopupState from './defaultState';

type state = typeof PopupState;
const localStorage = LocalStorageWorker.getLocalStorage();

const mutations = {
  setDefault(state: state) {
    Object.assign(state, PopupState);
  },
  loggedIn(state: state, token: string) {
    if (localStorage.add('token', token)) state.loggedInStatus = true;
    else state.loggedInStatus = false;
  },
  logout(state: state) {
    localStorage.clear();
    state.loggedInStatus = false;
  },
};

export default mutations;
