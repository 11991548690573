import defaultState from './defaultState';
import mutations from './mutations';

const state = defaultState;

const app = {
  namespaced: true,
  state,
  mutations,
  actions: undefined,
};

export default app;

export type popupKey = keyof typeof defaultState;
