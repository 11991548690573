















































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { SearchI } from '@/lib/typedef/search';
import Admin, { AdminI } from '@/lib/typedef/admin';
import InviteByEmail from '@/components/popups/InviteByEmail.vue';
import ConfirmDelete from '@/components/popups/ConfirmDeleteAdmin.vue';
import SearchBar from '@/components/organisms/SearchBar.vue';
import { store } from '@/store';

@Component({
  name: 'AdminList',
  components: {
    InviteByEmail,
    ConfirmDelete,
    SearchBar,
  },
})
export default class AdminList extends Vue {
  headers: DataTableHeader[] = [
    { text: '', value: 'isYourself', width: '5rem' },
    { text: '名前', value: 'fullName', class: 'table-column-width-3' },
    { text: 'メールアドレス', value: 'email', class: 'table-column-width-5' },
    {
      text: '作成日',
      value: 'createdAt',
      align: 'end',
      class: 'table-small-column-width-1',
    },
    { text: '', value: 'actions', align: 'end', sortable: false },
  ];

  data: AdminI[] = [];
  profile = new Admin();

  totalRecords = 0;

  searchItems: SearchI[] = [
    { text: '全て', value: '' },
    { text: '名前', value: 'name' },
    { text: 'メールアドレス', value: 'email' },
  ];

  idToDelete = '';

  async getProfile() {
    const { getProfile } = this.RepoFac.Profile;
    const responce = await getProfile();
    if (responce) {
      this.profile = responce;
    }
  }

  openConfirmDeletePopup(id: string) {
    this.idToDelete = id;
    store.commit('popup')('setPopup', 'ConfirmDeleteAdmin');
  }

  openInviteByEmailPopup() {
    store.commit('popup')('setPopup', 'InviteByEmail');
  }

  @Watch('$route', { immediate: true })
  async getAdminList() {
    this.getProfile();
    const { getAdminList } = this.RepoFac.Admin;
    const responce = await getAdminList({
      ...this.$route.query,
    });
    if (responce) {
      this.data = responce.data;
      this.totalRecords = responce.pagination.totalRecords;
    }
  }
}
