













































import { Component, Mixins, Prop } from 'vue-property-decorator';
import Popup from '@/lib/mixins/popup';
import Validation from '@/lib/mixins/validation';
import PopupBase from '@/components/popups/Base.vue';

@Component({
  name: 'InviteByEmail',
  components: { PopupBase },
})
export default class InviteByEmail extends Mixins(Popup, Validation) {
  @Prop({ type: Boolean, default: false, required: false })
  readonly isForgotPassword!: boolean;

  popupKey = 'InviteByEmail' as const;

  email = '';

  async sendAction() {
    if (this.isForgotPassword) {
      const { forgotPassword } = this.RepoFac.Login;
      const responce = await forgotPassword(this.email);
      if (responce) {
        this.$emit('email-sent');
        this.closePopup();
      }
    } else {
      const { inviteAdmin } = this.RepoFac.Admin;
      const responce = await inviteAdmin(this.email);
      if (responce) {
        this.$emit('admin-invited');
        this.closePopup();
      }
    }
  }
}
