import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { beforeAdmin, beforeLogout, beforeEachRouting } from './function';

Vue.use(VueRouter);

// if page had only 1 view, use slot and 1 route
// if page had sub view, use router view and nested route
const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'AdminView',
    component: () =>
      import(
        /* webpackChunkName: "adminView" */ '@/views/layouts/AdminLayout.vue'
      ),
    redirect: { name: 'AdminSetting' },
    beforeEnter: beforeAdmin,

    children: [
      {
        path: 'family-group',
        name: 'FamilyGroup',
        component: () =>
          import(
            /* webpackChunkName: "FamilyGroupList" */
            '@/views/pages/FamilyGroupList.vue'
          ),
      },
      {
        path: 'family-group/:id',
        name: 'FamilyGroupDetail',
        component: () =>
          import(
            /* webpackChunkName: "FamilyGroupDetail" */
            '@/views/pages/FamilyGroupDetail.vue'
          ),
      },
      // {
      //   path: 'statistics',
      //   name: 'Statistics',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "Statistics" */
      //       '@/views/pages/Statistics.vue'
      //     ),
      // },
      {
        path: 'term-policy',
        name: 'TermAndPolicy',
        component: {
          render: c => c('router-view'),
        },
        redirect: { name: 'Term' },
        children: [
          {
            path: 'term-of-service',
            name: 'Term',
            component: () =>
              import(
                /* webpackChunkName: "TermAndPolicyList" */
                '@/views/pages/TermAndPolicyList.vue'
              ),
          },
          {
            path: 'term-of-service/:id',
            name: 'TermDetail',
            component: () =>
              import(
                /* webpackChunkName: "TermAndPolicyDetail" */
                '@/views/pages/TermAndPolicyDetail.vue'
              ),
          },
          {
            path: 'privacy-policy',
            name: 'Policy',
            component: () =>
              import(
                /* webpackChunkName: "TermAndPolicyList" */
                '@/views/pages/TermAndPolicyList.vue'
              ),
          },
          {
            path: 'privacy-policy/:id',
            name: 'PolicyDetail',
            component: () =>
              import(
                /* webpackChunkName: "TermAndPolicyDetail" */
                '@/views/pages/TermAndPolicyDetail.vue'
              ),
          },
          {
            path: 'commercial-code',
            name: 'CommercialCode',
            component: () =>
              import(
                /* webpackChunkName: "TermAndPolicyList" */
                '@/views/pages/TermAndPolicyList.vue'
              ),
          },
          {
            path: 'commercial-code/:id',
            name: 'CommercialCodeDetail',
            component: () =>
              import(
                /* webpackChunkName: "TermAndPolicyDetail" */
                '@/views/pages/TermAndPolicyDetail.vue'
              ),
          },
        ],
      },
      {
        path: 'user-report',
        name: 'UserReport',
        component: () =>
          import(
            /* webpackChunkName: "UserReportList" */
            '@/views/pages/UserReportList.vue'
          ),
      },
      {
        path: 'user-report/:id',
        name: 'UserReportDetail',
        component: () =>
          import(
            /* webpackChunkName: "UserReportDetail" */
            '@/views/pages/UserReportDetail.vue'
          ),
      },
      {
        path: 'admin',
        name: 'AdminSetting',
        component: () =>
          import(
            /* webpackChunkName: "AdminList" */
            '@/views/pages/AdminList.vue'
          ),
      },
      {
        path: 'admin/profile',
        name: 'ProfileDetail',
        component: () =>
          import(
            /* webpackChunkName: "ProfileDetail" */
            '@/views/pages/ProfileDetail.vue'
          ),
      },
    ],
  },

  {
    path: '/renew-password',
    name: 'RenewPassword',
    component: () =>
      import(
        /* webpackChunkName: "RenewPassword" */ '@/views/pages/RenewPassword.vue'
      ),
  },

  {
    path: '/first-login',
    name: 'FirstLogin',
    component: () =>
      import(
        /* webpackChunkName: "RenewPassword" */ '@/views/pages/RenewPassword.vue'
      ),
  },

  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "Login" */ '@/views/pages/Login.vue'),
  },

  {
    path: '/logout',
    name: 'Logout',
    beforeEnter: beforeLogout,
  },

  {
    path: '*',
    name: 'Not Found',
    component: () =>
      import(/* webpackChunkName: "NotFound" */ '@/views/pages/NotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(beforeEachRouting);

export default router;
