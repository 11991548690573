










































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { SearchI } from '@/lib/typedef/search';
import Dropdown from '@/components/base-components/Dropdown.vue';
import isEmptyObject from '@/lib/functions/isEmptyObject';

@Component({
  name: 'SearchBar',
  components: {
    Dropdown,
  },
})
export default class SearchBar extends Vue {
  @Prop({ type: Array, default: [], required: true })
  readonly items!: SearchI[];

  searchOption: SearchI = {
    text: '全て',
    value: '',
  };
  searchValue = '';

  keywordDropdown: SearchI = {
    text: '',
    value: '',
  };

  @Watch('isKeywordDropdown')
  resetsearchValue(val: boolean) {
    if (!val) this.searchValue = '';
  }

  @Watch('searchOption.value')
  removeQuery(val: string) {
    if (val === '') {
      if (!isEmptyObject(this.$route.query)) {
        this.resetsearchValue(false);
        this.$router.replace({ path: this.$route.path });
      }
    }
  }

  get isKeywordDropdown() {
    if (this.$route.name === 'FamilyGroup')
      if (this.searchOption.value === 'prime') return true;
    if (this.$route.name === 'UserReport')
      if (this.searchOption.value === 'reportStatusId') return true;
    if (
      this.$parent.$route.matched.some(
        (route) => route.name === 'TermAndPolicy'
      )
    )
      if (this.searchOption.value === 'published') return true;
    return false;
  }

  get keywordDropdownOptions(): SearchI[] {
    if (this.$route.name === 'FamilyGroup')
      return [
        { text: 'プライム', value: 'true' },
        { text: '普通', value: 'false' },
      ];
    if (this.$route.name === 'UserReport')
      return [
        { text: '処理待ち', value: '1' },
        { text: '処理中', value: '2' },
        { text: '処理完了', value: '3' },
      ];
    if (
      this.$parent.$route.matched.some(
        (route) => route.name === 'TermAndPolicy'
      )
    )
      return [
        { text: '公開中', value: 'true' },
        { text: '未公開', value: 'false' },
      ];
    return [{ text: '', value: '' }];
  }
}
