























































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { TermAndPolyI } from '@/lib/typedef/term&condition';
import { DataTableHeader } from 'vuetify';
import { SearchI } from '@/lib/typedef/search';
import ConfirmDelete from '@/components/popups/ConfirmDeleteTermAndPolicy.vue';
import SearchBar from '@/components/organisms/SearchBar.vue';
import { store } from '@/store';

@Component({
  name: 'TermAndPolicy',
  components: {
    ConfirmDelete,
    SearchBar,
  },
})
export default class TermAndPolicy extends Vue {
  idToDelete = '';

  list: TermAndPolyI[] = [];

  totalRecords = 0;

  searchItems: SearchI[] = [
    { text: '全て', value: '' },
    { text: 'バージョン', value: 'version' },
    { text: '状態', value: 'published' },
  ];

  headers: DataTableHeader[] = [
    {
      text: 'バージョン',
      value: 'version',
      class: 'table-small-column-width-2',
    },
    {
      text: '内容',
      value: 'content',
      sortable: false,
      class: 'table-column-width-6',
    },
    { text: '状態', value: 'published', class: 'table-small-column-width-1' },
    {
      text: '作成日',
      value: 'createdAt',
      align: 'end',
      class: 'table-small-column-width-1',
    },
    { text: '', value: 'actions', align: 'center', sortable: false },
  ];

  get type() {
    if (this.$route.name === 'Term') return 'term';
    if (this.$route.name === 'Policy') return 'policy';
    return 'CommercialCode';
  }

  get getListType() {
    if (this.$route.name === 'Term') {
      return this.RepoFac.Term.getItemList;
    }
    if (this.$route.name === 'Policy') {
      const { getItemList } = this.RepoFac.Policy;
      return getItemList;
    }
    const { getItemList } = this.RepoFac.CommercialCode;
    return getItemList;
  }

  ellipsisContent(item: unknown, index: number) {
    const termContent = document.getElementsByClassName('term-content')[index]
      .textContent;
    if (termContent)
      document.getElementsByClassName('term-content')[
        index
      ].textContent = `${termContent.slice(0, 30)}...`;
  }

  async deleteItem(id: string) {
    this.idToDelete = id;
    store.commit('popup')('setPopup', 'ConfirmDeleteTermAndPolicy');
  }

  @Watch('$route')
  async getList() {
    const responce = await this.getListType({
      ...this.$route.query,
    });
    if (responce) {
      this.list = responce.data;
      this.totalRecords = responce.pagination.totalRecords;
    }
  }

  async mounted() {
    await this.getList();
    this.list.forEach(this.ellipsisContent);
  }
}
