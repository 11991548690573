import { Vue, Component } from 'vue-property-decorator';
import { popupKey } from '@/store/popup';
import { store } from '@/store';

/**
 * Inject popupKey type and function to popup components
 *
 * @mixin
 */
@Component
export default class Popup extends Vue {
  popupKey: popupKey = 'ResetPassword';

  closePopup() {
    store.commit('popup')('unsetPopup', this.popupKey);
  }
}
