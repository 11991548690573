
















































































import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'MemberAddIcon',
  components: {},
})
export default class MemberAddIcon extends Vue {}
