import { NavigationGuard } from 'vue-router';
import LocalStorageWorker from '@/lib/storage/StorageHelper';
import { store } from '@/store';

export const beforeAdmin: NavigationGuard = (to, from, next) => {
  const localStorage = LocalStorageWorker.getLocalStorage();
  if (!localStorage.get('token')) {
    next({ name: 'Login' });
  } else next();
};

export const beforeLogout: NavigationGuard = (to, from, next): void => {
  store.commit('auth')('logout');
  next({ name: 'Login' });
};

export const beforeEachRouting: NavigationGuard = (to, from, next) => {
  const DEFAULT_TITLE = 'aimemo管理コンソール';
  document.title = DEFAULT_TITLE;
  next();
};
