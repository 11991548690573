import { AdminI } from '../typedef/admin';
import Repo, { NoAuthAxiosInstance } from './Repository';

interface LoginI {
  dataUser: AdminI;
  token: string;
}

const resource = '/admin';

const Login = {
  async auth(email: string, password: string) {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    try {
      const { data } = await NoAuthAxiosInstance.post<LoginI>(
        `${resource}/login`,
        formData
      );
      return data.token;
    } catch (error) {
      return false;
    }
  },

  async changePassword(params: { oldPassword: string; newPassword: string }) {
    const formData = new FormData();
    formData.append('oldPassword', params.oldPassword);
    formData.append('newPassword', params.newPassword);
    const data = await Repo.put(`${resource}/change-password`, formData);
    if (data.request.status >= 400) return false;
    return true;
  },

  async firstLogin(params: {
    email: string;
    code: string;
    newPassword: string;
  }) {
    const formData = new FormData();
    formData.append('email', params.email);
    formData.append('code', params.code);
    formData.append('newPassword', params.newPassword);
    const { data } = await Repo.post<LoginI>(
      `${resource}/first-login`,
      formData
    );
    if (!data) return false;
    return data.token;
  },

  async forgotPassword(email: string) {
    const formData = new FormData();
    formData.append('email', email);
    const { data } = await Repo.post(`${resource}/forgot-password`, formData);
    if (!data) return false;
    return true;
  },

  async resetPassword(params: {
    email: string;
    code: string;
    newPassword: string;
  }) {
    const formData = new FormData();
    formData.append('email', params.email);
    formData.append('code', params.code);
    formData.append('newPassword', params.newPassword);
    const { data } = await Repo.post<LoginI>(
      `${resource}/reset-password`,
      formData
    );
    if (!data) return false;
    return data.token;
  },
};

export default Login;
