























































































import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'MemberIcon',
  components: {},
})
export default class MemberIcon extends Vue {}
