import { Vue, Component } from 'vue-property-decorator';
import RepoFac from './https/RepositoryFactory';

@Component({
  name: 'GlobalMixin',
})
export default class GlobalMixin extends Vue {
  RepoFac = RepoFac;
}

Vue.mixin(GlobalMixin);
