import {
  FamilyI,
  FamilyMemberI,
  InvitedFamilyMemberI,
} from '@/lib/typedef/family';
import { ResponseListI } from '@/lib/typedef/responceList';
import Repo from './Repository';

const resource = '/families';

const User = {
  async getFamilyList(params?: {
    pageSize?: number;
    page?: number;
    familyName?: string;
    ownerFullName?: string;
    ownerEmail?: string;
  }) {
    const { data } = await Repo.get<ResponseListI<FamilyI>>(resource, {
      params,
    });
    if (!data) return false;
    return data;
  },

  async getFamilyDetail(id: string) {
    const { data } = await Repo.get<FamilyI>(`${resource}/${id}`);
    if (!data) return false;
    return data;
  },

  async getFamilyMemberList(params?: { familyId?: string }) {
    const { data } = await Repo.get<FamilyMemberI[]>('family-members', {
      params,
    });
    if (!data) return false;
    return data;
  },

  async getFamilyInvitedMemberList(params?: { familyId?: string }) {
    const { data } = await Repo.get<InvitedFamilyMemberI[]>(
      'family-invite-codes',
      {
        params,
      }
    );
    if (!data) return false;
    return data;
  },
};

export default User;
