





































import { Component, Mixins, Prop } from 'vue-property-decorator';
import Popup from '@/lib/mixins/popup';
import PopupBase from './Base.vue';

@Component({
  name: 'ConfirmDeleteUserReportPopup',
  components: { PopupBase },
})
export default class ConfirmDeleteUserReportPopup extends Mixins(Popup) {
  @Prop({ type: String, default: '', required: true }) readonly id!: string;

  popupKey = 'ConfirmDeleteUserReport' as const;

  async deleteUserReport() {
    const { deleteUserReport } = this.RepoFac.UserReport;
    const responce = await deleteUserReport(this.id);
    if (responce) {
      this.$emit('deleted');
      this.closePopup();
    }
  }
}
