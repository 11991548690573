
















































































































import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'GiftIcon',
  components: {},
})
export default class GiftIcon extends Vue {}
