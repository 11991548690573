const state = {
  ResetPassword: false,
  MemberDetail: false,
  EmailDetail: false,
  InviteByEmail: false,
  ConfirmDeleteAdmin: false,
  ConfirmDeleteTermAndPolicy: false,
  ConfirmDeleteUserReport: false,
};

export default state;
