
























































































































import { Mixins, Component } from 'vue-property-decorator';
import Validation from '@/lib/mixins/validation';
import UserReport from '@/lib/typedef/userReport';
import { DataTableHeader } from 'vuetify';
import ConfirmDeleteUserReport from '@/components/popups/ConfirmDeleteUserReport.vue';
import Dropdown from '@/components/base-components/Dropdown.vue';
import { store } from '@/store';
import style from '@/scss/export.scss';

@Component({
  name: 'UserReportDetail',
  components: {
    ConfirmDeleteUserReport,
    Dropdown,
  },
})
export default class UserReportDetail extends Mixins(Validation) {
  userInfoHeader: DataTableHeader[] = [
    { text: 'メールアドレス', value: 'email', sortable: false },
    { text: '名前', value: 'fullName', sortable: false },
    { text: '報告日', value: 'createdAt', align: 'end', sortable: false },
  ];

  style = style;
  isShowSnackbar = false;

  data = new UserReport();

  statusList = [
    { color: 'primary', text: '処理待ち', value: 1 },
    { color: 'info', text: '処理中', value: 2 },
    { color: 'black', text: '処理完了', value: 3 },
  ] as const;

  get reportStatus() {
    return this.statusList[this.data.status - 1];
  }

  set reportStatus({ value }) {
    this.data.status = value;
  }

  async getUserReport() {
    const { getUserReport } = this.RepoFac.UserReport;
    const responce = await getUserReport(this.$route.params.id);
    if (responce) this.data = new UserReport(responce);
  }

  async deleteReport() {
    store.commit('popup')('setPopup', 'ConfirmDeleteUserReport');
  }

  async updateUserReport() {
    const { updateUserReport } = this.RepoFac.UserReport;
    const responce = await updateUserReport(this.data);
    if (responce) {
      this.isShowSnackbar = true;
      this.getUserReport();
    }
  }

  mounted() {
    this.getUserReport();
  }
}
