






























































































import { Mixins, Component } from 'vue-property-decorator';
import Validation from '@/lib/mixins/validation';
import Admin from '@/lib/typedef/admin';
import ConfirmDelete from '@/components/popups/ConfirmDeleteAdmin.vue';
import { store } from '@/store';

@Component({
  name: 'Profile',
  components: {
    ConfirmDelete,
  },
})
export default class Profile extends Mixins(Validation) {
  data = new Admin();

  isShowSnackbar = false;

  openConfirmDeletePopup() {
    store.commit('popup')('setPopup', 'ConfirmDeleteAdmin');
  }

  async getProfile() {
    const { getProfile } = this.RepoFac.Profile;
    const responce = await getProfile();
    if (responce) {
      this.data = responce;
    }
  }

  async updateProfile() {
    const { updateProfile } = this.RepoFac.Profile;
    const responce = await updateProfile(this.data);
    if (responce) {
      this.isShowSnackbar = true;
      this.getProfile();
    }
  }

  mounted() {
    this.getProfile();
  }
}
