import axios from 'axios';
import LocalStorageWorker from '@/lib/storage/StorageHelper';
import FormatDate from '@/lib/functions/dateTime';

const baseURL = process.env.VUE_APP_API_URL;

function authHeader() {
  const localStorage = LocalStorageWorker.getLocalStorage();
  const token = localStorage.get('token');

  if (!token) return { 'Content-Type': 'application/json' };
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
}

const axiosInstance = axios.create({
  baseURL,
  responseType: 'json',
});

export const NoAuthAxiosInstance = axios.create({
  baseURL,
  responseType: 'json',
});

axiosInstance.interceptors.request.use(
  config => ({ ...config, headers: authHeader() }),
  err => err
);

const trimLengthCreatedAt = (obj: { [index: string]: unknown }) => {
  let localObj = obj;
  if ('createdAt' in localObj)
    localObj = {
      ...localObj,
      createdAt: FormatDate((localObj.createdAt as string).slice(0, 10)),
    };
  return localObj;
};

axiosInstance.interceptors.response.use(
  response => {
    const localResponse = response;
    const { data } = localResponse;
    // check if data is object and not null to use "in" operator
    if (typeof data === 'object' && data !== null)
      if ('data' in data) {
        const dataList = data.data;
        if (Array.isArray(dataList))
          localResponse.data.data = dataList.map(item =>
            trimLengthCreatedAt(item)
          );
      } else if (Array.isArray(data))
        localResponse.data = data.map(item => trimLengthCreatedAt(item));
      else localResponse.data = trimLengthCreatedAt(localResponse.data);

    return localResponse;
  },
  err => {
    if (err.response.status === 401) document.location.href = '/logout';
    return Promise.reject(err);
  }
);

export default axiosInstance;
