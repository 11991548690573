import { AdminI } from '../typedef/admin';
import Repo from './Repository';

const resource = '/admin';

const Profile = {
  async getProfile() {
    const { data } = await Repo.get<AdminI>(`${resource}/profile`);
    if (!data) return undefined;
    return data;
  },

  async updateProfile(params: AdminI) {
    const formData = new FormData();
    formData.append('email', params.email);
    formData.append('fullName', params.fullName);

    const { data } = await Repo.put<AdminI>(`${resource}/update`, formData);

    if (!data) return false;
    return data;
  },
};

export default Profile;
