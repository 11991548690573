import { ResponseListI } from '@/lib/typedef/responceList';
import { AdminI } from '@/lib/typedef/admin';
import Repo from './Repository';

const resource = '/admin';

const Admin = {
  async getAdminList(params?: {
    pageSize?: number;
    page?: number;
    name?: string;
    email?: string;
  }) {
    const { data } = await Repo.get<ResponseListI<AdminI>>(`${resource}/list`, {
      params,
    });
    if (!data) return false;
    return data;
  },

  async deleteAdmin(id: string) {
    const { data } = await Repo.delete(`${resource}/delete/${id}`);
    if (!data) return false;
    return true;
  },

  async inviteAdmin(email: string) {
    const formData = new FormData();
    formData.append('email', email);
    const { data } = await Repo.post(`${resource}/invite`, formData);
    if (!data) return false;
    return true;
  },
};

export default Admin;
