var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('confirm-delete',{attrs:{"id":_vm.idToDelete},on:{"deleted":_vm.getList}}),_c('v-col',{staticClass:"d-flex justify-space-between",attrs:{"cols":"12"}},[_c('h2',{staticClass:"page-title"},[_vm._v(" "+_vm._s(_vm.$route.name === 'Term' ? '利用規約' : _vm.$route.name === 'Policy' ? 'プライバシーポリシー' : '特商法')+" ")]),_c('v-btn',{staticClass:"px-5",attrs:{"dark":"","color":"primary","to":{path: _vm.$route.path + '/new'}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" $vuetify.icons.document ")]),_vm._v(" 新規作成 ")],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('search-bar',{staticClass:"mb-10",attrs:{"items":_vm.searchItems}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"term-and-policy-table",attrs:{"headers":_vm.headers,"items":_vm.list,"server-items-length":_vm.totalRecords,"sort-by":"version","sort-desc":[false]},scopedSlots:_vm._u([{key:"item.content",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('span',{ref:item.id,staticClass:"term-content d-inline-flex",domProps:{"innerHTML":_vm._s(value)}})]}},{key:"item.published",fn:function(ref){
var value = ref.value;
return [(value)?_c('span',{staticClass:"info--text"},[_vm._v("公開中")]):_c('span',[_vm._v("未公開")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-4",attrs:{"color":"info","to":{path: ((_vm.$route.path) + "/" + (item.id))}}},[_vm._v(" 編集する ")]),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"negative","disabled":item.published,"dark":!item.published},on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_vm._v(" 削除する ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }