export interface AdminI {
  id: string;
  fullName: string;
  email: string;
  createdAt: string;
  isYourself: boolean;
}

export default class Admin implements AdminI {
  id = '';
  fullName = '';
  email = '';
  createdAt = '';
  isYourself = false;
}
