


























































import { Vue, Component, Watch } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { UserReportI } from '@/lib/typedef/userReport';
import { SearchI } from '@/lib/typedef/search';
import SearchBar from '@/components/organisms/SearchBar.vue';
import ConfirmDeleteUserReport from '@/components/popups/ConfirmDeleteUserReport.vue';
import { store } from '@/store';

@Component({
  name: 'UserReportList',
  components: {
    SearchBar,
    ConfirmDeleteUserReport,
  },
})
export default class UserReportList extends Vue {
  rpHeaders: DataTableHeader[] = [
    { text: 'メールアドレス', value: 'email', class: 'table-column-width-3' },
    {
      text: '内容',
      value: 'content',
      sortable: false,
      class: 'table-column-width-5',
    },
    { text: '状態', value: 'status', class: 'table-small-column-width-1' },
    {
      text: '報告日',
      value: 'createdAt',
      align: 'end',
      class: 'table-small-column-width-1',
    },
    { text: '', value: 'actions', align: 'end', sortable: false },
  ];

  idToDelete = '';

  data: UserReportI[] = [];

  totalRecords = 0;

  searchItems: SearchI[] = [
    { text: '全て', value: '' },
    { text: 'メールアドレス', value: 'email' },
    { text: '状態', value: 'reportStatusId' },
  ];

  @Watch('$route')
  async getUserReportList() {
    const { getUserPortList } = this.RepoFac.UserReport;
    const responce = await getUserPortList({
      ...this.$route.query,
    });
    if (responce) {
      this.data = responce.data.map((report) => ({
        ...report,
        fullName: `${report.lastName} ${report.firstName}`,
      }));
      this.totalRecords = responce.pagination.totalRecords;
    }
  }

  async deleteReport(id: string) {
    this.idToDelete = id;
    store.commit('popup')('setPopup', 'ConfirmDeleteUserReport');
  }

  mounted() {
    this.getUserReportList();
  }
}
