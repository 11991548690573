





























































import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'DocumentIcon',
  components: {},
})
export default class DocumentIcon extends Vue {}
