import { Vue, Component } from 'vue-property-decorator';
import { requiredRule, emailRule } from '@/lib/validation/formRules';

/**
 * Inject validation rules and attribute to component
 *
 * @mixin
 */
@Component
export default class Validation extends Vue {
  requiredRule = requiredRule;
  emailRule = emailRule;
  isFormValid = false;
}
