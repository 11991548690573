

















import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'BaseButton',
  components: {},
})
export default class BaseButton extends Vue {}
