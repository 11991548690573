import Vue from 'vue';
import Vuex from 'vuex';
// eslint-disable-next-line import/no-cycle
import { Store, RootState, myStore } from './types';
import popup from './popup';
import auth from './auth';

/**
 * all module must use namespace
 * don't use any state, mutation, actions, getter in the index file
 * declare an "app" module instead
 */

Vue.use(Vuex);
/* <-----------------------------------------DECLARE ROOT STATE------------------------------------------> */
export const state = {};

/* <-----------------------------------------DECLARE MODULES------------------------------------------> */
export const modules = {
  popup,
  auth,
};

/* <-----------------------------------------------------VUEX STORE-----------------------------------------------------> */
const UseStore: Store = new Vuex.Store<RootState>({
  modules,
  strict: process.env.NODE_ENV !== 'production',
});
export default UseStore;

/* <-----------------------------------------------------TYPED STORE-----------------------------------------------------> */
export const store: myStore = {
  ...UseStore,
  state: UseStore.state,
  commit(module) {
    return (key, payload, options?) =>
      UseStore.commit(`${module}/${key}`, payload, options);
  },
  dispatch(module) {
    return (key, payload, options?) =>
      UseStore.dispatch(`${module}/${key}`, payload, options);
  },
};
