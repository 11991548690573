export interface TermAndPolyI {
  id: string;
  version: string;
  content: string;
  published: boolean;
  createdAt?: string;
}

export default class TermAndPoly implements TermAndPolyI {
  id = '';
  version = '';
  content = '';
  published = false;

  constructor(termAndPoly?: TermAndPolyI) {
    Object.assign(this, termAndPoly);
  }
}
