export interface FamilyI {
  id: string;
  name: string;
  owner: string;
  createdAt: string;
  ownerFirstName: string;
  ownerLastName: string;
  joinedMember: number;
  numberOfMemory: number;
  primeExpirationDate: string;
  prime: boolean;
}

export default class Family implements FamilyI {
  id = '';
  name = '';
  owner = '';
  createdAt = '';
  ownerFirstName = '';
  ownerLastName = '';
  joinedMember = 0;
  numberOfMemory = 0;
  primeExpirationDate = '';
  prime = false;
}

export interface FamilyMemberI {
  id: string;
  nickname: string;
  createdAt: string;
  firstName: string;
  lastName: string;
  fullName: string;
  FamilyPositionName: string;
  currentPoint: number;
  allPoint: number;
  grade: string;
  isFamilyOwner: boolean;
}

export interface InvitedFamilyMemberI {
  id: string;
  code: string;
  createdAt: string;
  familyPositionName: string;
}
