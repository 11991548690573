
























































import { Vue, Component, Prop } from 'vue-property-decorator';
import { PropType } from 'vue';
import { SearchI } from '@/lib/typedef/search';

@Component({
  name: 'Dropdown',
  components: {},
  inheritAttrs: false,
})
export default class Dropdown extends Vue {
  @Prop({ type: [Array, String], default: () => [], required: false })
  readonly activatorClass!: string[] | string;

  @Prop({
    type: Object as PropType<SearchI>,
    default: {
      text: '',
      value: '',
    },
    required: false,
  })
  readonly value!: SearchI;

  @Prop({
    type: Array,
    default: [],
    required: true,
  })
  readonly itemList!: SearchI[];

  uniqueClassNameId = Math.random().toString(16).slice(2);
  menu = false;

  dropdownBlur(event: FocusEvent) {
    if (
      event.relatedTarget !== null &&
      (event.relatedTarget as Element).classList.contains(
        this.uniqueClassNameId
      )
    )
      return;
    this.menu = false;
  }
}
