





































import { Component, Mixins, Prop } from 'vue-property-decorator';
import Popup from '@/lib/mixins/popup';
import PopupBase from './Base.vue';

@Component({
  name: 'ConfirmDeleteTermAndPolicyPopup',
  components: { PopupBase },
})
export default class ConfirmDeleteTermAndPolicyPopup extends Mixins(Popup) {
  @Prop({ type: String, default: '', required: true }) readonly id!: string;

  popupKey = 'ConfirmDeleteTermAndPolicy' as const;

  get getDeleteType() {
    if (this.$route.name === 'Term' || this.$route.name === 'TermDetail') {
      return this.RepoFac.Term.deleteItem;
    }
    if (this.$route.name === 'Policy' || this.$route.name === 'PolicyDetail') {
      return this.RepoFac.Policy.deleteItem;
    }
    return this.RepoFac.CommercialCode.deleteItem;
  }

  async deleteTermAndPolicy() {
    const responce = await this.getDeleteType(this.id);
    if (responce) {
      this.$emit('deleted');
      this.closePopup();
    }
  }
}
