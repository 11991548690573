import { UserReportI } from '@/lib/typedef/userReport';
import { ResponseListI } from '@/lib/typedef/responceList';
import Repo from './Repository';

const resource = '/user-reports';

const UserReport = {
  async getUserPortList(params?: {
    pageSize?: number;
    page?: number;
    fullName?: string;
    email?: string;
    reportStatusId?: -1 | 1 | 2 | 3;
  }) {
    const { data } = await Repo.get<ResponseListI<UserReportI>>(resource, {
      params,
    });
    if (!data) return false;
    return data;
  },

  async getUserReport(id: string) {
    const { data } = await Repo.get<UserReportI>(`${resource}/${id}`);
    if (!data) return false;
    return data;
  },

  async updateUserReport(params: UserReportI) {
    const { id, content, status, note } = params;
    const { data } = await Repo.put<UserReportI>(resource, {
      id,
      content,
      status,
      note,
    });
    if (!data) return false;
    return data;
  },

  async deleteUserReport(id: string) {
    const { data } = await Repo.delete<number>(`${resource}/${id}`);
    if (!data) return false;
    return data;
  },
};

export default UserReport;
