

































import { Vue, Component, Watch } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { FamilyI } from '@/lib/typedef/family';
import { SearchI } from '@/lib/typedef/search';
import SearchBar from '@/components/organisms/SearchBar.vue';

@Component({
  name: 'FamilyGroupList',
  components: {
    SearchBar,
  },
})
export default class FamilyGroupList extends Vue {
  headers: DataTableHeader[] = [
    { text: 'グループ名前', value: 'name', class: 'table-column-width-4' },
    { text: '代表者', value: 'ownerFullName', class: 'table-column-width-1' },
    {
      text: '家族人数',
      value: 'joinedMember',
      align: 'end',
      class: 'table-small-column-width-1',
    },
    {
      text: 'メモリー使用状況',
      value: 'numberOfMemory',
      align: 'end',
      class: 'table-small-column-width-3',
    },
    {
      text: 'ステータス',
      value: 'prime',
      align: 'end',
      class: 'table-small-column-width-1',
    },
    {
      text: '作成日',
      value: 'createdAt',
      align: 'end',
      class: 'table-small-column-width-1',
    },
    {
      text: '',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ];

  data: (FamilyI & { ownerFullName: string })[] = [];

  totalRecords = 0;

  searchItems: SearchI[] = [
    { text: '全て', value: '' },
    { text: 'グループ名前', value: 'familyName' },
    { text: '代表者', value: 'ownerFullName' },
    { text: 'ステータス', value: 'prime' },
  ];

  @Watch('$route', { immediate: true })
  async getFamilyList() {
    const { getFamilyList } = this.RepoFac.Family;
    const responce = await getFamilyList({
      ...this.$route.query,
    });
    if (responce) {
      this.data = responce.data.map((family) => ({
        ...family,
        ownerFullName: `${family.ownerLastName} ${family.ownerFirstName}`,
      }));
      this.totalRecords = responce.pagination.totalRecords;
    }
  }
}
