





































































import { Vue, Component } from 'vue-property-decorator';

interface Router {
  icon: string;
  text: string;
  route: string;
}

interface NestedRoute {
  [index: string]: Router & {
    child?: { [index: string]: Omit<Router, 'icon'> };
    group?: string;
  };
}

@Component({
  name: 'TheSideBar',
  components: {},
})
export default class TheSideBar extends Vue {
  menuList: NestedRoute = {
    FamilyGroup: {
      icon: 'members',
      text: '家族グループ',
      route: 'FamilyGroup',
    },
    // Statistics: {
    //   icon: 'statistic',
    //   text: '統計情報',
    //   route: 'Statistics',
    // },
    UserReport: {
      icon: 'issue',
      text: '問題の報告',
      route: 'UserReport',
    },
    TermAndPolicy: {
      icon: 'document',
      text: 'ポリシーと規約',
      route: 'TermAndPolicy',
      child: {
        Term: { text: '利用規約', route: 'Term' },
        Policy: { text: 'プライバシーポリシー', route: 'Policy' },
        CommercialCode: { text: '特商法表示', route: 'CommercialCode' },
      },
      group: 'term-policy',
    },
    AdminSetting: {
      icon: 'setting',
      text: '管理者設定',
      route: 'AdminSetting',
    },
    Logout: {
      icon: 'logout',
      text: 'ログアウト',
      route: 'Logout',
    },
  };

  getIcon(icon: string | undefined) {
    if (icon !== undefined) return `$vuetify.icons.${icon}`;
    return '$vuetify.icons.members';
  }

  changeRoute(route: string) {
    if (route === 'TermAndPolicy') return;
    this.$router.push({ name: route });
  }
}
