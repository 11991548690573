import PopupState from './defaultState';

type state = typeof PopupState;
const mutations = {
  setDefault(state: state) {
    Object.assign(state, PopupState);
  },
  setPopup(state: state, key: keyof state) {
    state[key] = true;
  },
  unsetPopup(state: state, key: keyof state) {
    state[key] = false;
  },
};

export default mutations;
