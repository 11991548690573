











import { Vue, Component, Prop } from 'vue-property-decorator';
import { PropType } from 'vue';
import { popupKey } from '@/store/popup';
import { store } from '@/store';

@Component({
  name: 'PopupBase',
  components: {},
})
export default class PopupBase extends Vue {
  @Prop({ type: String as PropType<popupKey>, default: '', required: true })
  readonly popupKey!: popupKey;

  get popupState() {
    return store.state.popup[this.popupKey];
  }
}
