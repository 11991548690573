var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({attrs:{"no-data-text":"なし","footer-props":{ 
    showCurrentPage: true,
    itemsPerPageText: 'ページにおける表示数の変更',
    itemsPerPageOptions: [10, 20, 50, 100],
  },"header-props":{ sortIcon: '$vuetify.icons.arrowVertical' },"must-sort":"","sort-by":_vm.orderBy,"sort-desc":_vm.sortDesc,"page":_vm.page,"items-per-page":_vm.isDetailPage ? 100 : _vm.pageSize},on:{"update:sort-by":function($event){_vm.orderBy = $event},"update:sort-desc":function($event){$event[0] ? _vm.desc = ' DESC' : _vm.desc = ''},"pagination":function($event){return _vm.updatePagination($event)}},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(ref){
  var pageStart = ref.pageStart;
  var pageStop = ref.pageStop;
  var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s((pageStart + " - " + pageStop + " / " + itemsLength))+" ")]}},_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}})],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }