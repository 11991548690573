import Repo from './Repository';
import { TermAndPolyI } from '../typedef/term&condition';
import { ResponseListI } from '../typedef/responceList';

const TermAndPolicy = (resource: string) => ({
  async getItemList(params?: {
    page?: number;
    pageSize?: number;
    version?: string;
    published?: boolean;
  }) {
    const { data } = await Repo.get<ResponseListI<TermAndPolyI>>(resource, {
      params,
    });
    if (!data) return false;
    return data;
  },

  async getItemDetail(id?: string) {
    const { data } = await Repo.get<TermAndPolyI>(`${resource}/${id}`);
    if (!data) return false;
    return data;
  },

  async updateItem(params: Omit<TermAndPolyI, 'createdAt'>) {
    try {
      return await Repo.put<TermAndPolyI>(resource, params);
    } catch (err) {
      if (
        err.response?.data?.message?.indexOf(
          'duplicate key value violates unique constraint'
        ) > -1
      )
        return 'Duplicate version';
      return false;
    }
  },

  async createItem(params: Omit<TermAndPolyI, 'createdAt'>) {
    try {
      return await Repo.post<TermAndPolyI>(resource, params);
    } catch (err) {
      if (
        err.response?.data?.message?.indexOf(
          'duplicate key value violates unique constraint'
        ) > -1
      )
        return 'Duplicate version';
      return false;
    }
  },

  async deleteItem(id: string) {
    try {
      await Repo.delete(`${resource}/${id}`);
      return true;
    } catch (error) {
      return false;
    }
  },
});

export const Term = TermAndPolicy('/terms');
export const Policy = TermAndPolicy('/policies');
export const CommercialCode = TermAndPolicy('/special-commercial-codes');
