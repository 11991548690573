



























































































import { Vue, Component } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import Family, {
  FamilyMemberI,
  InvitedFamilyMemberI,
} from '@/lib/typedef/family';

@Component({
  name: 'FamilyGroupDetail',
  components: {},
})
export default class FamilyGroupDetail extends Vue {
  FamilyBasicInfoHeader: DataTableHeader[] = [
    {
      text: 'グループ名前',
      value: 'name',
      sortable: false,
      class: 'table-column-width-3',
    },
    {
      text: '家族人数',
      value: 'joinedMember',
      align: 'end',
      sortable: false,
      class: 'table-column-width-2',
    },
    {
      text: 'メモリー使用状況',
      value: 'numberOfMemory',
      align: 'end',
      sortable: false,
      class: 'table-column-width-2',
    },
    {
      text: 'ステータス',
      value: 'prime',
      align: 'end',
      sortable: false,
      class: 'table-column-width-2',
    },
    { text: '作成日', value: 'createdAt', align: 'end', sortable: false },
  ];

  JoinedMemberHeader: DataTableHeader[] = [
    {
      text: '',
      value: 'isFamilyOwner',
      width: '5rem',
      sort: (a) => {
        if (a) return -1;
        return 1;
      },
    },
    { text: '名前', value: 'fullName', class: 'table-column-width-3' },
    { text: 'メールアドレス', value: 'email', class: 'table-column-width-3' },
    {
      text: '家族との関係',
      value: 'familyPositionName',
      class: 'table-column-width-3',
    },
    {
      text: 'ポイント',
      value: 'allPoint',
      align: 'end',
      class: 'table-small-column-width-1',
    },
    {
      text: 'バッジ',
      value: 'grade',
      align: 'end',
      class: 'table-small-column-width-1',
    },
    { text: '登録日', value: 'createdAt', align: 'end' },
  ];

  InvitedMemberHeader: DataTableHeader[] = [
    { text: '家族との関係', value: 'familyPositionName' },
    { text: '招待日', value: 'createdAt' },
  ];

  familyDetail = new Family();

  familyMemberList: FamilyMemberI[] = [];
  InvitedFamilyMemberList: InvitedFamilyMemberI[] = [];

  async getFamilyDetail() {
    const { getFamilyDetail } = this.RepoFac.Family;
    const responce = await getFamilyDetail(this.$route.params.id);
    if (responce) this.familyDetail = responce;
  }

  async getFamilyMemberList() {
    const { getFamilyMemberList } = this.RepoFac.Family;
    const respone = await getFamilyMemberList({
      familyId: this.$route.params.id,
    });
    if (respone) {
      this.familyMemberList = respone.map((member) => ({
        ...member,
        grade: member.grade ? member.grade : '0',
        fullName: `${member.lastName} ${member.firstName}`,
        // point: `${member.currentPoint} / ${member.allPoint}`,
      }));
    }
  }

  async getInvitedFamilyMemberList() {
    const { getFamilyInvitedMemberList } = this.RepoFac.Family;
    const responce = await getFamilyInvitedMemberList({
      familyId: this.$route.params.id,
    });
    if (responce) this.InvitedFamilyMemberList = responce;
  }

  tableHeight(row: number) {
    const headerHeight = 50;
    const rowHeight = 70;
    if (row === 0) return headerHeight + rowHeight;
    if (row > 5) return headerHeight + 5 * rowHeight;
    return headerHeight + row * rowHeight;
  }

  mounted() {
    this.getFamilyDetail();
    this.getFamilyMemberList();
    this.getInvitedFamilyMemberList();
  }
}
