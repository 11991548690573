






























































import { Mixins, Component, Prop, Watch } from 'vue-property-decorator';
import { PropType } from 'vue';
import Validation from '@/lib/mixins/validation';
import TermAndPolicy, { TermAndPolyI } from '@/lib/typedef/term&condition';
import style from '@/scss/export.scss';

@Component({
  name: 'TermAndPolicyCard',
  components: {},
})
export default class TermAndPolicyCard extends Mixins(Validation) {
  @Prop({
    type: String as PropType<'term' | 'policy' | 'CommercialCode'>,
    default: 'term',
    required: true,
  })
  readonly type!: 'term' | 'policy' | 'CommercialCode';

  @Prop({
    type: Object as PropType<TermAndPolyI>,
    default: () => new TermAndPolicy(),
    required: false,
  })
  readonly term!: TermAndPolyI;

  @Prop({ type: Boolean, default: false, required: false })
  readonly empty!: boolean;

  data = new TermAndPolicy(this.term);

  duplicatedVersion = '';

  @Watch('data.version')
  emptyDuplicatedVersionError() {
    if (this.duplicatedVersion) this.duplicatedVersion = '';
  }

  get versionRule() {
    return [
      this.requiredRule,
      (value: string) =>
        /^\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(value) ||
        'バージョンは x.y.zである必要があります。ここで、x、y、zは3桁未満の数値です。',
    ];
  }

  style = style;

  @Watch('term')
  updateData(val: TermAndPolicy) {
    this.data = new TermAndPolicy(val);
  }

  get isCreatingNew() {
    return this.$route.params.id === 'new';
  }

  get saveAction() {
    if (this.isCreatingNew) {
      if (this.type === 'term') {
        const { createItem } = this.RepoFac.Term;
        return createItem;
      }
      if (this.type === 'policy') {
        const { createItem } = this.RepoFac.Policy;
        return createItem;
      }

      const { createItem } = this.RepoFac.CommercialCode;
      return createItem;
    }

    if (this.type === 'term') {
      const { updateItem } = this.RepoFac.Term;
      return updateItem;
    }
    if (this.type === 'policy') {
      const { updateItem } = this.RepoFac.Policy;
      return updateItem;
    }

    const { updateItem } = this.RepoFac.CommercialCode;
    return updateItem;
  }

  async save(isPublish: boolean) {
    (this.data as TermAndPolyI).createdAt = undefined;
    this.data.published = isPublish;
    const responce = await this.saveAction(this.data);

    if (responce) {
      if (responce === 'Duplicate version') {
        this.duplicatedVersion = 'バージョンは既に存在しています。';
        return;
      }

      this.$emit('change-saved');
      if (this.isCreatingNew) {
        this.$router.replace({ params: { id: responce.data.id } });
      }
    }
  }
}
