export interface UserReportI {
  id: string;
  content: string;
  status: 1 | 2 | 3;
  note: string;
  createdAt: string;
  lastName: string;
  firstName: string;
  fullName: string;
  email: string;
}

export default class UserReport implements UserReportI {
  id = '';
  content = '';
  status: 1 | 2 | 3 = 1;
  note = '';
  createdAt = '';
  lastName = '';
  firstName = '';
  fullName = `${this.lastName} ${this.firstName}`;
  email = '';
  constructor(Report?: UserReportI) {
    Object.assign(this, Report);
    if (Report) this.fullName = `${Report.lastName} ${Report.firstName}`;
  }
}
