






















































import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'SettingIcon',
  components: {},
})
export default class SettingIcon extends Vue {}
