import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import variables from '@/scss/export.scss';
import MembersIcon from '@/assets/icons/members-icon.vue';
import StatisticIcon from '@/assets/icons/statistic-icon.vue';
import IssueIcon from '@/assets/icons/issue-icon.vue';
import SettingIcon from '@/assets/icons/setting-icon.vue';
import LogoutIcon from '@/assets/icons/logout-icon.vue';
import DocumentIcon from '@/assets/icons/document-icon.vue';
import MemberIcon from '@/assets/icons/member-icon.vue';
import GiftIcon from '@/assets/icons/gift-icon.vue';
import MemberAddIcon from '@/assets/icons/member-add-icon.vue';
import LockIcon from '@/assets/icons/lock-icon.vue';
import ArrowVerticalIcon from '@/assets/icons/arrow-vertical-icon.vue';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: variables.primary,
        secondary: variables.secondary,
        accent: variables.accent,
        error: '#b71c1c',
        info: '#4792DE',
        negative: variables.negative,
        black: variables.text,
      },
    },
  },
  icons: {
    values: {
      arrowVertical: {
        component: ArrowVerticalIcon,
      },
      gift: {
        component: GiftIcon,
      },
      members: {
        component: MembersIcon,
      },
      member: {
        component: MemberIcon,
      },
      memberAdd: {
        component: MemberAddIcon,
      },
      statistic: {
        component: StatisticIcon,
      },
      logout: {
        component: LogoutIcon,
      },
      issue: {
        component: IssueIcon,
      },
      setting: {
        component: SettingIcon,
      },
      document: {
        component: DocumentIcon,
      },
      lock: {
        component: LockIcon,
      },
    },
  },
  breakpoint: {
    thresholds: {
      xs: 500,
      sm: 800,
      md: 1100,
      lg: 1500,
    },
    scrollBarWidth: 8,
  },
});
