import { render, staticRenderFns } from "./ConfirmDeleteTermAndPolicy.vue?vue&type=template&id=1be06aac&scoped=true&"
import script from "./ConfirmDeleteTermAndPolicy.vue?vue&type=script&lang=ts&"
export * from "./ConfirmDeleteTermAndPolicy.vue?vue&type=script&lang=ts&"
import style0 from "./ConfirmDeleteTermAndPolicy.vue?vue&type=style&index=0&id=1be06aac&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1be06aac",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle})
