






























import { Vue, Component } from 'vue-property-decorator';
import LoginLayout from '@/views/layouts/LoginLayout.vue';

@Component({
  name: 'NotFound',
  components: {
    LoginLayout,
  },
})
export default class NotFound extends Vue {}
