




































import { Vue, Component, Watch } from 'vue-property-decorator';

@Component({
  name: 'BaseDataTable',
  components: {},
})
export default class BaseDataTable extends Vue {
  orderBy = this.defaultOrderBy();
  defaultOrderBy() {
    if (this.$route.query.orderBy)
      return (this.$route.query.orderBy as string).replace(' DESC', '');
    if (this.$attrs['sort-by']) return this.$attrs['sort-by'];
    return '';
  }

  desc = this.defaultDesc();
  defaultDesc() {
    if (this.$route.query.orderBy)
      if ((this.$route.query.orderBy as string).indexOf(' DESC') > -1)
        return ' DESC';
      else return '';
    if (this.$attrs['sort-desc']) return ' DESC';
    return '';
  }

  sortDesc =
    this.$attrs['sort-desc'] !== undefined ? this.$attrs['sort-desc'] : [true];

  get isDetailPage() {
    const routeName = this.$route.name;
    if (routeName && routeName.indexOf('Detail') > -1) return true;

    return false;
  }

  @Watch('orderBy')
  @Watch('desc', { immediate: true })
  orderByFetch(value: string) {
    if (this.isDetailPage) return;

    if (value === ' DESC') this.sortDesc = [true];
    else this.sortDesc = [false];

    if (this.$route.query.orderBy !== this.orderBy + this.desc)
      this.$router.push({
        query: { ...this.$route.query, orderBy: this.orderBy + this.desc },
      });
  }

  updatePagination(pagination: Record<'page' | 'itemsPerPage', number>) {
    if (this.isDetailPage) return;

    this.page = pagination.page;
    this.pageSize = pagination.itemsPerPage;
  }

  get page() {
    if (this.$route.query.page) return +this.$route.query.page;
    return 1;
  }
  set page(value) {
    if (this.$route.query.page !== `${value}`)
      this.$router.push({
        query: { ...this.$route.query, page: `${value}` },
      });
  }

  get pageSize() {
    if (this.$route.query.pageSize) return +this.$route.query.pageSize;
    return 10;
  }
  set pageSize(value) {
    if (this.$route.query.pageSize !== `${value}`)
      this.$router.push({
        query: { ...this.$route.query, pageSize: `${value}` },
      });
  }
}
