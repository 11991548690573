export default class LocalStorageWorker {
  private static localStorage: LocalStorageWorker | undefined;
  static getLocalStorage() {
    if (this.localStorage === undefined)
      this.localStorage = new LocalStorageWorker();
    return this.localStorage;
  }

  localStorageSupported: boolean;
  private constructor() {
    this.localStorageSupported =
      typeof window.localStorage !== 'undefined' &&
      window.localStorage !== null;
  }

  add(key: 'token', item: string) {
    if (!this.localStorageSupported) return false;
    localStorage.setItem(key, item);
    return true;
  }

  get(key: string): string | null {
    if (!this.localStorageSupported) return null;
    const item = localStorage.getItem(key);
    return item;
  }

  remove(key: string) {
    if (!this.localStorageSupported) return;
    localStorage.removeItem(key);
  }

  clear() {
    if (!this.localStorageSupported) return;
    localStorage.clear();
  }
}
