import Family from './Family';
import Profile from './Profile';
import Admin from './Admin';
import Login from './Login';
import { Term, Policy, CommercialCode } from './TermAndPolicy';
import UserReport from './UserReport';

const Repositories = {
  Term,
  Family,
  CommercialCode,
  Policy,
  UserReport,
  Profile,
  Admin,
  Login,
};

export default Repositories;
