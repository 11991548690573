import Vue from 'vue';
import App from './App.vue';
import router from './router';
import defaultStore from './store';
import vuetify from './plugins/vuetify';
import '@mdi/font/css/materialdesignicons.css';
import '@/components/base-components/_globals';
import '@/lib/_global';

Vue.config.productionTip = false;

new Vue({
  router,
  store: defaultStore,
  vuetify,
  render: h => h(App),
}).$mount('#app');
