




























import { Vue, Component, Prop } from 'vue-property-decorator';
import style from '@/scss/export.scss';

@Component({
  name: 'BaseTextField',
  components: {},
})
export default class BaseTextField extends Vue {
  @Prop({ type: String, default: 'text', required: false })
  readonly type!: string;

  showPass = true;

  get appendIcon() {
    if (this.type !== 'password') return null;
    return this.showPass ? 'mdi-eye' : 'mdi-eye-off';
  }

  get backgroundColor() {
    // solo inverted do not work well with background color
    if (this.$attrs['solo-inverted']) return null;
    return this.$attrs['background-color'] ?? this.$attrs.disabled
      ? style.disable
      : style.inputFieldBG;
  }

  get color() {
    if (this.$attrs['solo-inverted']) return null;
    return this.$attrs.color ?? style.inputFieldBorder;
  }

  mounted() {
    if (this.type === 'password') this.showPass = false;
  }
}
